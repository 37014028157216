import { useState, useEffect } from "react";

// third party
import Reveal from "react-awesome-reveal";
import { toast } from "react-hot-toast";
import moment from "moment";

// @mui
import {
  Alert,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Paper,
  Typography,
  Card,
  CardContent,
  FormControl,
  NativeSelect,
  TextField,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// hooks
import { useWeb3React } from "@web3-react/core";
import useAuth from "src/hooks/useAuth";
import { utils } from "ethers";
import useLoadTokeninfo from "src/hooks/useLoadTokenInfo";

// utils
import { switchToNetwork } from "src/utils/wallet";
import { connectorLocalStorageKey, ConnectorNames } from "src/utils/connectors";
import { fadeInUp } from "src/utils/keyframes";

// components
import Container from "src/components/Container";
import Faq from "./Faq";

const CHAIN = {
  5: "Ethereum Testnet (Goerli)",
  97: "Binance Smart Chain Testnet",
};
interface CryptoProps {
  name: string;
  symbol: string;
  address: string;
  amount: string;
}
const CRYPTO = {
  5: [
    {
      name: "DFS Token",
      symbol: "DFS",
      address: "",
      amount: "3",
    },
    {
      name: "Shibuya Inu",
      symbol: "SHIBU",
      address: "",
      amount: "10",
    },
  ],
  97: [
    {
      name: "DIFINES ARMY",
      symbol: "ARMY",
      address: "0x705AC111cc3b9c1320Ceb2f9f64784D4b6310248",
      amount: "3",
    },
    {
      name: "Shibuya Inu Cash",
      symbol: "SHIBUC",
      address: "0x00C41b1ef74C5d2330Fb5397C59a92407Bc85Bd6",
      amount: "3",
    },
  ],
};

const Portfolio = () => {
  const { chainId, account, library } = useWeb3React();
  const { claimToken, getTime } = useLoadTokeninfo();
  const { loginWallet } = useAuth();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [chainInfo, setChainInfo] = useState<number>(97);
  const [selectedToken, setSelectedToken] = useState<CryptoProps>({
    name: "",
    symbol: "",
    address: "",
    amount: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [hash, setHash] = useState<string>("");
  const [passTime, setPassTime] = useState<number>(0);

  const handleNext = async () => {
    if (activeStep == 0 && (!walletAddress || !account)) return;
    if (activeStep == 1 && (!account || !selectedToken.name)) return;
    if (activeStep == steps.length - 1) {
      if (loading) return;
      if (moment.duration(passTime, "seconds").hours() < 7) return;
      setLoading(true);
      let res = await claimToken(chainId, account, selectedToken.symbol);
      setLoading(false);
      if (res.status == "200") {
        toast.success("Successfully claimed your token");
        console.log("hash: ", res.hash);
        setHash(res.hash);
      } else {
        toast.error(res.hash);
        console.log("hash: ", res.hash);
        return;
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
    setWalletAddress("");
    setSelectedToken({
      name: "",
      symbol: "",
      address: "",
      amount: "",
    });
    setHash("");
    setPassTime(0);
  };
  function connectWallet() {
    loginWallet(ConnectorNames.Injected);
    window.localStorage.setItem(
      connectorLocalStorageKey,
      ConnectorNames.Injected
    );
  }
  const changeNetwork = async (chainId: number) => {
    console.log(chainId);
    await switchToNetwork({ library, chainId });
  };
  const changeToken = (e: any) => {
    if (e.target.value == "")
      setSelectedToken({
        name: "",
        symbol: "",
        address: "",
        amount: "",
      });
    else
      setSelectedToken({
        name: JSON.parse(e.target.value).name,
        symbol: JSON.parse(e.target.value).symbol,
        address: JSON.parse(e.target.value).address,
        amount: JSON.parse(e.target.value).amount,
      });
  };
  const onCopyCardID = () => {
    toast.success("token address copied");
    navigator.clipboard.writeText(selectedToken.address);
  };

  const steps = [
    {
      label: "Binance Smart Chain Test Network",
      description: `Connect your wallet! We support MetaMask wallet.`,
      content: (
        <Box className="step-content">
          <Button
            variant="contained"
            size="large"
            className="connect-btn"
            color="info"
            onClick={connectWallet}
          >
            {account ? `${account}` : "Connect Wallet"}
          </Button>
          <Box className="border-line">or</Box>
          <TextField
            fullWidth
            disabled
            value={walletAddress}
            onChange={(e: any) => setWalletAddress(e.target.value)}
          />
          <Typography sx={{ color: "red" }}>
            {walletAddress && !utils.isAddress(walletAddress)
              ? "Invalid wallet address"
              : ""}
          </Typography>
        </Box>
      ),
    },
    {
      label: "Select Your Token",
      description: "Selct your token to claim",
      content: (
        <Box className="step-content">
          <Box
            onClick={() =>
              window.open("https://testnet.bnbchain.org/faucet-smart/")
            }
            sx={{
              cursor: "pointer",
              color: "rgb(137, 148, 159)",
            }}
          >
            <Typography className="blink-text">
              If you don't have Test BNB, get from here
            </Typography>
          </Box>
          <Box className="second-step">
            <FormControl fullWidth>
              <NativeSelect
                value={chainInfo}
                onChange={(e) => changeNetwork(Number(e.target.value))}
              >
                <option value={97}>{CHAIN[97]}</option>
                <option value={5}>{CHAIN[5]}</option>
              </NativeSelect>
            </FormControl>
            <FormControl fullWidth>
              <NativeSelect onChange={changeToken}>
                <option value={""}>--- Select your token ---</option>
                {CRYPTO[chainInfo]?.map((crypto: any, idx: number) => (
                  <option value={JSON.stringify(crypto)} key={idx}>
                    {crypto.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Box>

          <Typography variant="overline" className="warn-title">
            {selectedToken.name
              ? `You can recieve ${
                  selectedToken.amount + " " + selectedToken.symbol
                }
              once a day`
              : "Select your token"}
          </Typography>
        </Box>
      ),
    },
    {
      label: "Claim",
      description: `Click the claim button and wait for a second. 
      You can check the transaction with the hash by clicking.`,
    },
  ];

  useEffect(() => {
    async function init() {
      if (chainId > 0) setChainInfo(chainId);
      if (account) setWalletAddress(account);
      if (selectedToken.symbol && account) {
        let res = await getTime(selectedToken.symbol, account, chainId);
        setPassTime(res.passTime);
      }
    }
    init();
  }, [chainId, account, selectedToken]);

  return (
    <>
      <Box className="svg-background">
        <img src="./statistic/assets/landing/wave.svg" alt="banner" />
      </Box>
      <Box className="ld-portfolio" id="portfolio">
        <Container className="nav-container">
          <Box className="full">
            <Box className="top">
              <Reveal keyframes={fadeInUp} delay={300} triggerOnce>
                <Box className="content">DIFINES Token Faucet</Box>
              </Reveal>
              <Reveal keyframes={fadeInUp} delay={500} triggerOnce>
                <Box className="desc">
                  DIFINES has launched a service that allows even beginners to
                  easily issue tokens for free.
                </Box>
              </Reveal>
            </Box>
            <Box className="down">
              <Card className="step-card">
                <CardContent>
                  <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                      <Step key={step.label}>
                        <StepLabel>{step.label}</StepLabel>
                        <StepContent>
                          <Typography className="step-desc">
                            {step.description}
                          </Typography>
                          {step.content}
                          {index === steps.length - 1 &&
                            passTime > 0 &&
                            moment.duration(passTime, "seconds").hours() <
                              7 && (
                              <Alert severity="error" sx={{ mt: 3 }}>
                                Please come back in{" "}
                                {moment.duration(passTime, "seconds").hours() <
                                6
                                  ? 6 -
                                    moment
                                      .duration(passTime, "seconds")
                                      .hours() +
                                    " hours"
                                  : 60 -
                                    moment
                                      .duration(passTime, "seconds")
                                      .minutes() +
                                    " minutes"}{" "}
                              </Alert>
                            )}
                          <Box className="action-btn">
                            {index === steps.length - 1 &&
                            passTime > 0 &&
                            moment.duration(passTime, "seconds").hours() < 7 ? (
                              <></>
                            ) : (
                              <Button
                                variant="contained"
                                onClick={handleNext}
                                sx={{ mt: 4, mr: 1 }}
                              >
                                {index === steps.length - 1
                                  ? !loading
                                    ? `Claim my ${
                                        selectedToken.amount +
                                        " " +
                                        selectedToken.symbol
                                      }`
                                    : "loading..."
                                  : "Continue"}
                              </Button>
                            )}
                            {activeStep == 1 && (
                              <Button
                                variant="outlined"
                                disabled={index === 0}
                                onClick={handleBack}
                                sx={{ mt: 4, mr: 1 }}
                              >
                                Back
                              </Button>
                            )}
                          </Box>
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                  {activeStep === steps.length && (
                    <Paper
                      square
                      elevation={0}
                      sx={{
                        p: 3,
                        backgroundColor: "#2d3239",
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        sx={{ textAlign: "left", color: "rgb(137, 148, 159)" }}
                      >
                        All steps completed - you&apos;re finished
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          onClick={() =>
                            window.open(
                              `https://testnet.bscscan.com/token/${selectedToken.address}`
                            )
                          }
                          sx={{
                            cursor: "pointer",
                            color: "rgb(137, 148, 159)",
                            wordWrap: "break-word",
                          }}
                        >
                          {selectedToken.address}
                        </Typography>
                        <ContentCopyIcon
                          sx={{
                            marginLeft: "5px",
                            cursor: "pointer",
                            color: "rgb(137, 148, 159)",
                          }}
                          onClick={onCopyCardID}
                        />
                      </Box>
                      <Button
                        onClick={handleReset}
                        variant="outlined"
                        sx={{ mt: 4 }}
                      >
                        Reset
                      </Button>
                      <Button
                        onClick={() =>
                          window.open(`https://testnet.bscscan.com/tx/${hash}`)
                        }
                        variant="outlined"
                        sx={{ mt: 4, ml: 2 }}
                      >
                        Scan
                      </Button>
                    </Paper>
                  )}
                </CardContent>
              </Card>
              <Box className="faq-card">
                <Faq />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Portfolio;
