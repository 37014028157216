import Portfolio from "./white-creator-components/Portfolio";

const Landing = () => {
  return (
    <div className="landing" id="top">
      <Portfolio />
    </div>
  );
};

export default Landing;
