import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function ControlledAccordions() {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className="accordion-heading" sx={{ flexShrink: 0 }}>
            How does this work?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            DIFINES faucet operates reliable infrastructure for several
            blockchains. We created a faucet to make the developer experience
            seamless by providing testnet tokens for as many chains as we could.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className="accordion-heading" sx={{ flexShrink: 0 }}>
            What is a faucet?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            A faucet is a blockchain tool that drips testnet tokens to anyone
            that requests them for free. You typically use these tokens for
            creating test transactions on the associated blockchain, such as
            contract deployments, transferring funds and debugging failed
            transactions.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className="accordion-heading" sx={{ flexShrink: 0 }}>
            Are there rate limits?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes, we provide one drip per network every 6 hours.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className="accordion-heading" sx={{ flexShrink: 0 }}>
            What if it doesn't work?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Please contact the DIFINES support team</Typography>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
