import { HashLink as Link } from "react-router-hash-link";
import Container from "src/components/Container";

const Footer = () => {
  return (
    <div className="ld-footer">
      <Container className="nav-container">
        <div className="copyright">
          <h4>DIFINES @ 2023</h4>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
