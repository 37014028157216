// hooks
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { ethers } from "ethers";
import Web3Modal from "web3modal";

// utils
import ERC20ABI from "src/utils/abis/ERC20.json";
import ArmyABI from "src/utils/abis/ARMY.json";
import ShibucABI from "src/utils/abis/SHIBUC.json";

export const Networks = {
  MainNet: 56,
  Testnet: 97,
  Ethereum: 1,
  Goerli: 5,
};

export const TokenType = {
  1: "ERC20",
  5: "ERC20",
  56: "BEP20",
  97: "BEP20",
};

export const ALL_SUPPORTED_CHAIN_IDS: number[] = [
  Networks.MainNet,
  Networks.Testnet,
  Networks.Ethereum,
  Networks.Goerli,
];

interface ContractInfo {
  address: string;
  abi: any;
}

export const CONTRACTS_BY_NETWORK: {
  [key: number]: { [key: string]: ContractInfo };
} = {
  [Networks.MainNet]: {
    ARMY: {
      address: "",
      abi: ArmyABI.abi,
    },
    SHIBUC: {
      address: "",
      abi: ShibucABI.abi,
    },
  },
  [Networks.Testnet]: {
    ARMY: {
      address: "0x7Ec51c6d8103744605A1eB3999AD53b7af203Daa",
      abi: ArmyABI.abi,
    },
    SHIBUC: {
      address: "0x00C41b1ef74C5d2330Fb5397C59a92407Bc85Bd6",
      abi: ShibucABI.abi,
    },
  },
};

export const currentNetwork: number =
  parseInt(process.env.REACT_APP_NETWORK_ID || "") || 56;

export const shorter = (str: string) =>
  str?.length > 8 ? str.slice(0, 6) + "..." + str.slice(-4) : str;

export async function getErc20ContractInstance(address: string) {
  let provider = window.ethereum;
  const web3 = new Web3(provider);
  return new web3.eth.Contract(ERC20ABI as AbiItem[], address);
}

export function getContractInfo(
  name: string,
  chainId: number | undefined = undefined
) {
  if (!chainId) chainId = currentNetwork;

  const contracts = CONTRACTS_BY_NETWORK?.[chainId];
  if (contracts) {
    return contracts?.[name];
  } else {
    return null;
  }
}

export async function getFaucetTokenInstance(name: string, chainId: number) {
  let provider = window.ethereum;
  const web3 = new Web3(provider);
  const info = getContractInfo(name, chainId);
  return new web3.eth.Contract(info.abi, info.address);
}

export async function getContractInstance(abi: any, address: string) {
  let provider = window.ethereum;
  const web3 = new Web3(provider);
  return new web3.eth.Contract(abi as AbiItem[], address);
}

export const getProvider = async () => {
  const web3Modal = new Web3Modal();
  const connection = await web3Modal.connect();
  const provider = new ethers.providers.Web3Provider(connection);
  return provider;
};
