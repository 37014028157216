import { getFaucetTokenInstance } from "src/utils";

const useLoadTokeninfo = () => {
  const claimToken = async (
    chainId: number,
    account: string,
    tokenSymbol: string
  ) => {
    let resHash = "";
    try {
      const tokenInstance = await getFaucetTokenInstance(tokenSymbol, chainId);
      await tokenInstance.methods
        .faucet(account)
        .send({
          from: account,
        })
        .on("confirmation", function (confirmationNumber, receipt) {})
        .on("receipt", async function (receipt: any) {
          resHash = receipt.transactionHash;
        });

      return {
        status: "200",
        hash: resHash,
      };
    } catch (error) {
      return {
        status: "500",
        hash: "Please try after 6 hours",
      };
    }
  };
  const getTime = async (
    tokenSymbol: string,
    account: string,
    chainId: number
  ) => {
    let passTime = 0;
    try {
      const tokenInstance = await getFaucetTokenInstance(tokenSymbol, chainId);
      passTime = await tokenInstance.methods.passTime(account).call();
      return { passTime };
    } catch (err) {
      console.error(err);
    }
  };

  return {
    claimToken,
    getTime,
  };
};

export default useLoadTokeninfo;
